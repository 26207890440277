<template>
  <v-card>
    <v-container
      id="dashboard"
      fluid
      tag="section"
    >
      <v-toolbar dense>
        <v-toolbar-title>Produtos</v-toolbar-title>
        <v-spacer />

        <v-btn icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                color="blue"
                @click="onClick('N')"
                v-on="on"
              >
                mdi-thumb-up
              </v-icon>
            </template>
            <span>Aprovar</span>
          </v-tooltip>
        </v-btn>

        <v-btn icon>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="red"
                v-bind="attrs"
                class="ml-3"
                @click="onClick('R')"
                v-on="on"
              >
                mdi-thumb-down
              </v-icon>
            </template>
            <span>Reprovar</span>
          </v-tooltip>
        </v-btn>
        <v-btn
          icon
          color="blue"
          @click="onSairCadastro"
        >
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="ml-3"
                v-on="on"
              >
                mdi-exit-to-app
              </v-icon>
            </template>
            <span>Sair</span>
          </v-tooltip>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="lista"
      >
        <template v-slot:item.qtVenda="{ item }">
          {{ formatPrice(item.qtVenda) }}
        </template>
        <template v-slot:item.vlUnitario="{ item }">
          R$ {{ formatPrice(item.vlUnitario) }}
        </template>
        <template v-slot:item.vlAjuste="{ item }">
          {{ formatPriceAjuste(item.vlAjuste) }}
        </template>
      </v-data-table>
    </v-container>
    <v-dialog
      v-model="dialogAPRP"
      max-width="900"
    >
      <modalDialogRevisao
        v-if="dialogAPRP"
        :id="id"
        :cliente="cliente"
        :idloja="idloja"
        :status="status"
        @onSairCadastro="onSairCadastro"
        @onSucesso="onSucesso"
      />
    </v-dialog>
  </v-card>
</template>
<script>
  import orcamentoRep from './../../../components/services/orcamentoRepository'
  import modalDialogRevisao from './../componente-revisaoorcamento-aprovar-reprovar.vue'

  export default {
    components: {
      modalDialogRevisao,
    },
    props: {
      id: {
        type: Number,
        default: 0,
      },
      idloja: {
        type: Number,
        required: true,
      },
      cliente: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        lista: [],
        dialogAPRP: false,
        status: '',
        headers: [
          {
            text: 'Código',
            align: 'start',
            value: 'item',
            width: 100,
          },
          {
            text: 'Item',
            value: 'nmItem',
            width: 250,
          },
          {
            text: 'Und.',
            align: 'center',
            value: 'cdUnidade',
            width: 60,
          },
          {
            text: 'Ajuste',
            align: 'end',
            value: 'vlAjuste',
            width: 70,
          },
          {
            text: 'Qt.Ven.',
            align: 'end',
            value: 'qtVenda',
            width: 70,
          },
          {
            text: 'Vl.Unit.',
            align: 'end',
            value: 'vlUnitario',
            width: 80,
          },
        ],
      }
    },
    created () {
      this.get()
    },
    methods: {
      async get () {
        try {
          this.$store.commit('setSplashScreen', true)
          await orcamentoRep.ItensPedido(this.id).then(response => {
            this.$store.commit('setSplashScreen', false)
            if (response.data.status === 'sucesso') {
              this.lista = response.data.dados
            } else if (response.data.status === 'mensagem') {
              this.$swal({
                icon: 'warning',
                text: response.data.dados,
              })
            } else if (response.data.status === 'error') {
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            }
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {

        }
      },
      formatPriceAjuste (value) {
        var val = (value / 1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      onClick (status) {
        this.status = status
        this.dialogAPRP = true
      },
      onSairCadastro () {
        this.$emit('onSairCadastro')
      },
      onSucesso () {
        this.$emit('onSucesso')
      },
    },
  }
</script>
