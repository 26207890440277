<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-card>
      <v-card-title>
        Revisão de Orçamentos
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="listaRevisaoOrcamento"
        :search="search"
      >
        <template v-slot:item.nmVendedor="{ item }">
          {{ item.cdVendedor }}-{{ item.nmVendedor }}
        </template>
        <template v-slot:item.vlTotal="{ item }">
          R$ {{ formatPrice(item.vlTotal) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <!--11111 item recebe o que tem  na tela ao clicar, envia para OnAprovar-->
          <v-icon
            small
            color="blue"
            @click="onClickAvaliar(item)"
          >
            mdi-text-box-search-outline
          </v-icon>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                color="blue"
                @click="onAprovar(item)"
                v-on="on"
              >
                mdi-thumb-up
              </v-icon>
            </template>
            <span>Aprovar</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="red"
                v-bind="attrs"
                class="ml-3"
                @click="onReprovar(item)"
                v-on="on"
              >
                mdi-thumb-down
              </v-icon>
            </template>
            <span>Reprovar</span>
          </v-tooltip> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- <v-row justify="center">
      <v-dialog
        v-model="dialogAprovar"
        persistent
        max-width="800"
      >
        <modalRevisaoOrcamentoAprovar
          v-if="dialogAprovar"
          :cdorcamento="idRevisaoOrcamento"
          :cliente="cliente"
          :cdloja="idloja"
          @onSairCadastro="onSairCadastro"
          @onSucesso="onSucesso"
        />
      </v-dialog>
    </v-row> -->
    <!-- <v-dialog
      v-model="dialogReprovar"
      persistent
      max-width="800"
    >
      <modalRevisaoOrcamentoReprovar
        v-if="dialogReprovar"
        :cdorcamento="idRevisaoOrcamento"
        :cliente="cliente"
        :cdloja="idloja"
        @onSairCadastro="onSairCadastro"
        @onSucesso="onSucesso"
      />
    </v-dialog> -->
    <v-dialog
      v-model="dialogCarregarItens"
      max-width="900"
    >
      <modalItens
        v-if="dialogCarregarItens"
        :id="idOrcamento"
        :cliente="cliente"
        :idloja="idloja"
        @onSairCadastro="onSairCadastro"
        @onSucesso="onSucesso"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  import revisaoOrcamentoRep from './../../components/services/revisaoOrcamentoRepository.js'
  import transacaoRep from './../../components/services/transacaoRepository'
  // import modalRevisaoOrcamentoAprovar from './../../../dashboard/pages/componentes/componente-revisaoorcamento-aprovar'
  // import modalRevisaoOrcamentoReprovar from './../../../dashboard/pages/componentes/componente-revisaoorcamento-reprovar.vue'
  import modalItens from '../componentes/revisaopedido/modal-itemrevisao.vue'

  export default {
    components: {
      // modalRevisaoOrcamentoAprovar,
      // modalRevisaoOrcamentoReprovar,
      modalItens,
    },
    data () {
      return {
        search: '',
        listaRevisaoOrcamento: [],
        headers: [
          {
            text: 'Loja',
            align: 'start',
            value: 'nmLoja',
            width: 120,
          },
          {
            text: 'Prazo',
            align: 'start',
            value: 'nmPrazo',
            width: 120,
          },
          {
            text: 'Data Orçamento',
            align: 'start',
            value: 'dtOrcamentoFormatada',
            width: 130,
          },
          {
            text: 'Nº',
            align: 'center',
            value: 'nrOrcamento',
            width: 70,
          },
          {
            text: 'Vendedor',
            align: 'center',
            value: 'nmVendedor',
            width: 120,
          },
          {
            text: 'Cliente',
            align: 'start',
            value: 'nmCliente',
            width: 230,
          },
          {
            text: 'Valor',
            align: 'end',
            value: 'vlTotal',
            width: 100,
          },
          { text: '', value: 'actions', width: 100, sortable: false },
        ],
        aprovar: false,
        idRevisaoOrcamento: 0,
        idOrcamento: 0,
        idloja: 0,
        // Dialogs
        dialogAprovar: false,
        dialogReprovar: false,
        dialogCarregarItens: false,
        cliente: '',
        acessoLiberado: false,
      }
    },
    async created () {
      if (this.$store.getters.usuario.tpusuario !== 'A') {
        await this.getTransacao()
        if (this.acessoLiberado) {
          await this.getList()
        } else {
          this.$router.replace('/v1')
        }
      } else {
        await this.getList()
      }
    },
    methods: {
      async getList () {
        try {
          this.$store.commit('setSplashScreen', true)
          await revisaoOrcamentoRep.getList().then(response => {
            this.$store.commit('setSplashScreen', false)
            if (response.data.status === 'sucesso') {
              this.listaRevisaoOrcamento = response.data.dados
            } else if (response.data.status === 'mensagem') {
              this.$swal({
                icon: 'warning',
                text: response.data.dados,
              })
            } else if (response.data.status === 'error') {
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            }
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {

        }
      },
      onSairCadastro () {
        this.dialogCarregarItens = false
      },
      onSucesso () {
        this.dialogCarregarItens = false
        this.getList()
      },
      onAprovar (item) {
        // 222222 recebe dados de item as informações e envia para modal e return-->
        this.idRevisaoOrcamento = item.cdOrcamento
        this.cliente = item.nmCliente
        this.idloja = item.cdLoja
        this.aprovar = true
        this.dialogAprovar = true
      },
      onReprovar (item) {
        this.idRevisaoOrcamento = item.cdOrcamento
        this.idloja = item.cdLoja
        this.cliente = item.nmCliente
        this.reprovar = true
        this.dialogReprovar = true
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      async getTransacao () {
        this.$store.commit('setSplashScreen', true)
        var dados = {
          usuario: this.$store.getters.usuario.cdusuario,
          transacao: 'FP004REV',
        }
        await transacaoRep.get(dados).then(response => {
          if (response.data.status === 'mensagem') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'warning',
              text: response.data.dados,
              confirmButtonText: 'OK',
            }).then((result) => {
              this.acessoLiberado = false
              if (result.isConfirmed) {
                return false
              }
            })
          } else if (response.data.status === 'error') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
              confirmButtonText: 'OK',
            }).then((result) => {
              this.acessoLiberado = false
              if (result.isConfirmed) {
                return false
              }
            })
          } else {
            this.$store.commit('setSplashScreen', false)
            this.acessoLiberado = true
          }
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
      onClickAvaliar (item) {
        this.idOrcamento = item.cdOrcamento
        this.cliente = item.nmCliente
        this.idloja = item.cdLoja
        this.dialogCarregarItens = true
      },
    },
  }
</script>
<style>
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td{
    font-size:8pt;
  }
</style>
