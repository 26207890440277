<template>
  <v-card>
    <v-card>
      <v-toolbar
        dark
        color="#1A237E"
      >
        <v-toolbar-title v-if="status == 'R'">
          Deseja REPROVAR o orçamento do Cliente:<br><span color="amber">{{ cliente }} ?</span>
        </v-toolbar-title>
        <v-toolbar-title v-else>
          Deseja APROVAR o orçamento do Cliente:<br><span color="amber">{{ cliente }} ?</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          class="mx-2"
          small
          fab
          color="amber"
          @click="onAprovar"
        >
          <v-icon class="white--text">
            mdi-thumb-up
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          small
          fab
          color="light-green accent-4"
          @click="onSair"
        >
          <v-icon class="white--text">
            mdi-exit-to-app
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-card>
</template>
<script>
  import revisaoOrcamentoRep from '../../components/services/revisaoOrcamentoRepository'
  export default {
    props: {
      id: {
        type: Number,
        required: true,
      },
      idloja: {
        type: Number,
        required: true,
      },
      cliente: {
        type: String,
        required: true,
      },
      status: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        revisaoorcamento: { },
      }
    },
    methods: {
      async onAprovar () {
        try {
          this.$store.commit('setSplashScreen', true)
          this.revisaoorcamento.cdloja = this.idloja
          this.revisaoorcamento.cdorcamento = this.id
          this.revisaoorcamento.sttramitacao = 506
          this.revisaoorcamento.StOrcamento = this.status
          this.revisaoorcamento.CdUsuario = this.$store.getters.usuario.cdusuario
          await revisaoOrcamentoRep.Save(this.revisaoorcamento).then(response => {
            if (response.data.status === 'sucesso') {
              this.$emit('onSucesso')
            } else if (response.data.status === 'messagem') {
              this.$swal({
                icon: 'warning',
                text: response.data.messagem,
              })
            } else if (response.data.status === 'error') {
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            }
            this.$store.commit('setSplashScreen', false)
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {

        }
      },
      onSair () {
        this.$emit('onSairCadastro')
      },
    },
  }
</script>
